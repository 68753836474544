import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import SimplePageBanner from '../components/Common/SimplePageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
const Page404 = () => {
    return (
        <Layout>
            <Navbar />
            <SimplePageBanner />
            <div className="not-found">
                <div className="container text-center">
                    <h1>404: Page Not Found</h1>
                    <p>Somehow you found a page that doesn't exist. Try going back to the <Link to="/">homepage</Link> </p>
                </div>
            </div>
            <Footer />
        </Layout>
    );
}

export default Page404